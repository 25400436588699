"use client";
import { ErrorBoundary } from "react-error-boundary";

import { Body } from "app/(platform)/_components/Body";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import { useRouter } from "next/navigation";
import React from "react";

interface ErrorProviderProps {
   children: React.ReactNode;
   message?: string | undefined;
   errorAlreadyCaught?: boolean;
}
//TODO-ZW: Add telemetry for the url that caused the error
// Don't be fooled by trying Chrome or Firefox on your iPhone, either, as all browsers on IOS use Safari under the hood.
export default function ErrorProvider({
   children,
   message = "Something went wrong. If you're using Safari, try switching to Chrome or Firefox. If the issue persists, please tell us: support@potluckaction.com.",
   errorAlreadyCaught = false
}: ErrorProviderProps) {
   const router = useRouter();
   if (errorAlreadyCaught) {
      return (
         <Body>
            <_Stack alignment={"center"} gap={"lg"}>
               <_Paragraph label={message} />
               <_Button
                  testId={``}
                  variant={"primary"}
                  onButtonClick={() => {
                     location.reload();
                  }}
               >
                  Refresh
               </_Button>
               <_Button testId={``} variant={"primary"} onButtonClick={() => router.back()}>
                  Go back
               </_Button>
            </_Stack>
         </Body>
      );
   }
   return (
      <ErrorBoundary
         fallback={
            <Body>
               <_Stack alignment={"center"} gap={"lg"}>
                  <_Paragraph label={message} />
                  <_Button
                     testId={``}
                     variant={"primary"}
                     onButtonClick={() => {
                        location.reload();
                     }}
                  >
                     Refresh
                  </_Button>
                  <_Button testId={``} variant={"primary"} onButtonClick={() => router.back()}>
                     Go back
                  </_Button>
               </_Stack>
            </Body>
         }
      >
         {children}
      </ErrorBoundary>
   );
}
