import { VariantProps, cva } from "class-variance-authority";

type _DivProps = {} & VariantProps<typeof _DivVariants> & React.HTMLAttributes<HTMLDivElement>;

const _DivVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

// Use for <div> tag implementations that should be refactored to standard components.
function _DivUNSAFE({ children, ...props }: _DivProps) {
   if (children) {
      return <div {...props}>{children}</div>;
   }
   return <div {...props} />;
}

export default _DivUNSAFE;
