//window.getComputedStyle(document.querySelector("html")!).getPropertyValue("font-size") //window.innerWidth / parseFloat(getComputedStyle(document.querySelector("html")!)["font-size"])
//https://stackoverflow.com/questions/28687289/how-to-get-the-font-size-of-html-tag/28687607
export default function getRemSize() {
   if (typeof window === "undefined") {
      return 16;
   }
   if (typeof document === "undefined") {
      return 16;
   }
   const html = document?.querySelector("html");
   if (html) {
      const fontSize = window?.getComputedStyle(html).fontSize;
      return parseFloat(fontSize);
   }
   return 16;
}
