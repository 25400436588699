"use client";
import ILog from "app/_lib/global/Log";
import { VariantProps, cva } from "class-variance-authority";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import Link from "next/link";
import React from "react";

export type IButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
   VariantProps<typeof buttonClasses> &
   (
      | {
           href: string | undefined;
           onClick?: undefined;
           onButtonClick?: undefined;
           onAnchorClick?: React.MouseEventHandler<HTMLAnchorElement>;
        }
      | {
           href?: undefined;
           onButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined; //React.HtmlHTMLAttributes<HTMLButtonElement>["onClick"];
           onAnchorClick?: undefined;
        }
   ) & {
      disabled?: boolean;
      pointable?: boolean;
      testId: string | undefined;
      className?: string;
      type?: "button" | "submit" | "reset";
      router?: AppRouterInstance;
      condition?: boolean;
   };

//            w-[50%] cursor-pointer
//            place-self-center
//            rounded-lg bg-slate-300 p-4
export const buttonClasses = cva("font-normal flex place-items-center ", {
   variants: {
      height: {
         none: "",
         fit: "h-fit",
         min: "h-min"
      },
      width: {
         none: "",
         fit: "w-fit",
         min: "w-min"
      },
      variant: {
         primary: "bg-slate-300 hover:bg-slate-400 text-black",
         restorative: "bg-blue-400 hover:bg-blue-500 text-white",
         danger: "bg-red-300 hover:bg-red-400 text-white",
         transparent: "hover:underline",
         transparentWithoutHover: ""
      },

      rounded: {
         true: "rounded-md",
         false: ""
      },
      placement: {
         center: "place-self-center",
         none: "",
         start: "place-self-start"
      },
      wrap: {
         true: "text-wrap whitespace-normal",
         false: "text-nowrap"
      },
      // size: {
      //    none: "",
      //    sm: "text-xs p-1",
      //    md: "text-sm p-2",
      //    lg: "text-lg p-2"
      // },
      size: {
         none: "",
         sm: "text-xs px-2 py-1",
         md: "text-sm px-4 py-2",
         lg: "text-lg px-6 py-3"
      },
      disabled: {
         true: "opacity-50 cursor-not-allowed",
         false: ""
      },
      pointable: {
         true: "cursor-pointer",
         false: ""
      },
      active: {
         true: "bg-slate-400 p-2 rounded-md",
         false: ""
      },
      underlineActive: {
         true: " border-b-2 border-slate-400",
         false: "border-white"
      },
      maintainUnderline: {
         true: "border-b-2 ",
         false: ""
      },
      margin: {
         none: "",
         4: "m-4"
      }
   },
   compoundVariants: [
      {
         variant: ["primary", "restorative", "danger"],
         class: ["hover:drop-shadow-sm focus:outline-hidden rounded-sm transition ease-in-out duration-150", "rounded-md", "text-sm px-4 py-2"]
      }
   ],
   defaultVariants: {
      variant: "transparent",
      size: "none",
      rounded: false,
      disabled: false,
      active: false,
      underlineActive: false,
      maintainUnderline: false,
      margin: "none",
      placement: "center",
      wrap: false,
      pointable: true
   }
});

const _Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, IButtonProps>(
   (
      {
         type = "button",
         className,
         children,
         onClick,
         onButtonClick,
         onAnchorClick,
         href,
         variant = "transparent",
         size,
         disabled = false,
         testId,
         active = false,
         rounded,

         margin,
         placement,
         wrap,
         pointable = true,
         router,
         height = "fit",
         width = "fit",
         condition,
         underlineActive,
         maintainUnderline = false,
         ...props
      },
      ref
   ) => {
      const classes = buttonClasses({ variant, size, disabled, active, margin, placement, rounded, wrap, maintainUnderline, underlineActive, pointable: !!pointable && !disabled }) + ` ${className}`;

      //https://blog.logrocket.com/next-js-routechangestart-router-events/
      if (href) {
         return (
            <Link ref={ref as React.ForwardedRef<HTMLAnchorElement>} onClick={onAnchorClick} href={href} data-testid={`${testId}`} className={classes} prefetch={null}>
               {children}
            </Link>
         );
      } else
         return (
            <button
               ref={ref as React.ForwardedRef<HTMLButtonElement>}
               type={type}
               className={classes}
               onClick={(e) => {
                  ILog.v("Button_onMouseDown", { e });
                  if (e.button === 0) {
                     onButtonClick?.(e);
                  }
               }}
               disabled={disabled}
               data-testid={testId}
               {...props}
            >
               {children}
            </button>
         );
   }
);

_Button.displayName;

export default _Button;

// const _Button: React.FC<IButtonProps> = ({
//    type = "button",
//    className,
//    children,
//    onClick,
//    onButtonClick,
//    onAnchorClick,
//    href,
//    variant = "transparent",
//    size,
//    disabled = false,
//    testId,
//    active = false,
//    rounded,
//    margin,
//    placement,
//    wrap,
//    pointable = true,
//    router,
//    ...props
// }) => {
//    const classes = buttonClasses({ variant, size, disabled, active, margin, placement, rounded, wrap, pointable: !!pointable && !disabled }) + ` ${className}`;
//    if (href) {
//       return (
//          <Link onMouseDown={onAnchorClick} href={href} data-testid={`${testId}`} className={classes}>
//             {children}
//          </Link>
//       );
//    } else
//       return (
//          <button
//             type={type}
//             className={classes}
//             onMouseDown={(e) => {
//                ILog.v("Button_onMouseDown", { e });
//                if (e.button === 0) {
//                   onButtonClick?.(e);
//                }
//             }}
//             disabled={disabled}
//             data-testid={testId}
//             {...props}
//          >
//             {children}
//          </button>
//       );
// };
