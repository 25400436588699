import { cva, VariantProps } from "class-variance-authority";
import React from "react";

type PAStackProps = {
   children: React.ReactNode;
   className?: string;
   testId?: string;
} & VariantProps<typeof stackVariants> &
   React.HTMLAttributes<HTMLDivElement>;
//mx-auto
const stackVariants = cva([" "], {
   variants: {
      flexBehavior: {
         none: "",
         row: "flex flex-row",
         column: "flex flex-col"
      },
      listBehavior: {
         none: "list-none",
         disc: "list-disc",
         decimal: "list-decimal"
      },
      gap: {
         none: "",
         sm: "gap-1",
         md: "gap-3",
         lg: "gap-5"
      },
      padding: {
         none: "p-0",
         xs: "p-1",
         sm: "p-2",
         md: "p-4",
         lg: "p-8"
      },
      size: {
         none: "",
         sm: "max-w-sm",
         md: "max-w-md",
         lg: "max-w-lg",
         xl: "max-w-xl",
         fit: "max-w-fit"
      },
      fluid: {
         true: "w-full",
         false: ""
      },
      wrap: {
         true: "flex-wrap",
         false: ""
      },
      alignment: {
         start: "flex place-content-start",
         startCenter: "flex place-content-start place-items-center",
         center: "flex place-content-center place-items-center place-self-center",
         end: "flex place-content-end",
         between: "flex justify-between"
      }
   },
   defaultVariants: {
      padding: "none",
      size: "none",
      fluid: true,
      alignment: "start",
      flexBehavior: "column",
      gap: "md",
      listBehavior: "none",
      wrap: false
   },
   compoundVariants: [
      {
         fluid: false,
         class: "" //mx-auto
      }
   ]
});

const _Stack: React.FC<PAStackProps> = ({ children, testId, className = "", ...rest }) => {
   const combinedClasses = stackVariants({ ...rest }) + ` ${className}`;

   return (
      <div id={rest?.id} data-testid={testId} className={combinedClasses}>
         {children}
      </div>
   );
};

export default _Stack;
